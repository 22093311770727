/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000; 
  font-family: Arial, sans-serif;
} */

.sport-page-wrapper{
  z-index: 9999;
    position: absolute;
    width: 100%;
    margin-top: -56px;
}
.iframe-container {
  position: relative;
}
.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;
  background: #000; 
  font-family: Arial, sans-serif;

}

#spin_the_wheel {
  width: 90vw; 
  max-width: 400px; 
  position: relative;
  border: 15px solid #6ce3f0;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(108, 227, 240, 1); /* Outer shadow with #6ce3f0 */
}

.iframe-container canvas {
  width: 100% !important;
  height: auto !important;
  display: block;
}

#spin {
  font: 0.8/0 "Lato", sans-serif;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 47%;
  left: 47%;
  width: 35%;
  height: 35%;
  margin: -15%;
  background: url('./tree-stars-circle-button.png') no-repeat center center; /* Add background image */
  background-size: cover; /* Cover the entire div */
  transition: 0.8s;
}

.down-arrow {
  z-index: 1;
  position: absolute;
  top: -10px;
  margin: auto;
  left: 0px;
  right: 0px;
  text-align: center;
}

.iframe-container .buttons {
  position: absolute; 
  bottom: 20px;
  z-index: 100;
}

.sound-icon-container {
  position: absolute; 
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

.play-btn-container {
  position: absolute; 
  bottom: 20px;
  /* left: 130px; */
  z-index: 100;
}

.trophy-container { 
  position: absolute; 
  bottom: 20px;
  right: 20px; 
  z-index: 100;
}

#sound-toggle, #trophy-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 60px;
  color: #333;
  padding: 5px;
}

#sound-toggle, #trophy-button {
  margin: 5px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 50px;
  font-size: 23px;
  height: 50px;
  line-height: 50px;
}

#sound-toggle:hover, #trophy-button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

#sound-toggle:active, #trophy-button:active {
  transform: scale(0.95);
}
#play {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  width: 150px;
  font-size: 23px; margin: 5px; cursor: pointer;
  height: 50px;
  /* line-height: 50px; */
}
/* SpinWheel.css */
.iframe-container  .modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  justify-content: center;
  align-items: center;
}

.iframe-container  .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 0px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 500px; /* For larger screens */
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.won-style {
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  border: 8px solid #fff;
  border-radius: 30px;
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 40px;
}

.won-style h2 {
  color: #000;
  margin-top: 0;
  font-size: 1.8em;
  font-weight: bold;
}

.won-style p {
  color: #000;
  font-size: 1.2em;
  margin-bottom: 10px;
  margin-top: 16px;
}


.won-button {
  appearance: none;
  backface-visibility: hidden;
  background-color: #913333;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-top: 10px;
  width: 50%;
  margin-left: 25%;
}

.won-button:hover {
  background-color: #913333;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.won-button:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.won-button:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}

/* Loader Fullscreen */
#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}

/* Spinning Animation */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* Keyframe Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.play-btn-container .btn {
	width: 150px;
	height: 100px;
	background: #fff;
	border-radius: 50%;
	border: 10px solid #234B66;
	font-size: 28px;
	font-weight: bold;
	color: #234B66;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6);
}
.btn:active {
  background-color: #9b0909; color:#fff;
}

.btn:disabled {
  background: #9e5656; /* Gray background */
  border: 10px solid #ffd700; /* Slightly darker border */
  color: #fff;       /* Lighter text color */
  cursor: default;   /* Default cursor */
  box-shadow: none; /* Remove shadow */
  opacity: 0.7; /* Optional: Slightly transparent */
  transform: scale(0.95); /* Optional: Slightly smaller */
  transition: all 0.3s ease; /* Add transition for smooth changes */

}

/* Optional: Styles for hover effect (only when enabled) */
.btn:not(:disabled):hover {  /* Or #play:enabled:hover */
    transform: scale(1.05);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.7); /* Slightly larger shadow */
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  /* border: 1px solid blue; */
  width: 80%; /* Could be more or less */
  max-width: 600px; /* Set a maximum width */
  position: relative; /* For the close button positioning */
}
.close-button {
  position: absolute;
  top: -24px;
  right: -21px;
  font-size: 30px;
  cursor: pointer;
  background: #c46909;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #fff !important;
}

#winner-list {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

#winner-list li {
  gap: 0.5rem;
  background-color: rgba(227, 227, 227, 0.6);
  border-radius: 8px;
  position: relative;
  flex-direction: row;
  min-height: 1.7rem;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  padding: 0px 1rem;
  margin: 0.25rem;
  width: 70%;
  margin-left: 15%;
  backdrop-filter: blur(5px);
}
