.iframe-container {
  position: relative;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);;
  border: none;
  overflow: hidden;
}
